import React from "react";
import { parse, stringify } from "query-string";
import { Dropdown, Menu, Button, Radio, Input, Image } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import logo from "../logo.jpg";
import darklogo from "../darklogo.jpg";

const MENU_ITEMS = {
  myThreads: "My Threads",
  allThreads: "All Threads",
  home: "Thread Tracker",
  logout: "Logout",
};

function Navbar({
  setShowOwned,
  auth,
  currentUser,
  signOut,
  openLoginModal,
  isDarkMode,
  setIsDarkMode,
  options,
  setSelectedOptions,
  selectedOptions,
  setSearchTerm,
}) {
  const [activeItem, setActiveItem] = React.useState(MENU_ITEMS.home);
  const [localSearch, setLocalSearch] = React.useState("");
  React.useEffect(() => {
    const search = parse(window.location.search);
    if (search.term) {
      setLocalSearch(search.term);
      setSearchTerm(search.term);
    }
  }, [setLocalSearch, setSearchTerm]);
  return (
    <Menu
      vertical={isMobile}
      inverted={isDarkMode}
      style={{ width: "100%", textAlign: "center" }}
    >
      <Menu.Item
        onClick={() => {
          setSearchTerm("");
          setShowOwned(false);
          setActiveItem(MENU_ITEMS.home);
        }}
      >
        <Image size="small" src={isDarkMode ? darklogo : logo} />
      </Menu.Item>
      {currentUser && (
        <Menu.Item
          name={MENU_ITEMS.allThreads}
          active={activeItem === MENU_ITEMS.home}
          onClick={() => {
            setSearchTerm("");
            setShowOwned(false);
            setActiveItem(MENU_ITEMS.home);
          }}
        />
      )}
      {currentUser && (
        <Menu.Item
          name={MENU_ITEMS.myThreads}
          active={activeItem === MENU_ITEMS.myThreads}
          onClick={() => {
            setSearchTerm("");
            setShowOwned(true);
            setActiveItem(MENU_ITEMS.myThreads);
          }}
        />
      )}
      <Menu.Item>
        <Dropdown
          clearable
          placeholder="Thread Type"
          multiple
          search
          selection
          onChange={(e, { value }) => setSelectedOptions(value)}
          value={selectedOptions}
          options={options}
        />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>
          <label style={{ marginRight: "10px" }}>Dark Mode</label>
          <Radio
            toggle
            onChange={() => setIsDarkMode(!isDarkMode)}
            checked={isDarkMode}
          />
        </Menu.Item>
        <Menu.Item>
          <Input
            value={localSearch}
            onChange={(e) => {
              setLocalSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                window.location.search = stringify({ term: e.target.value });
                setSearchTerm(localSearch);
              }
            }}
            icon="search"
            placeholder="Search..."
          />
        </Menu.Item>
        {currentUser ? (
          <Menu.Item>
            <Dropdown text={currentUser.email} floating labeled>
              <Dropdown.Menu>
                <Dropdown.Header>
                  <Button
                    fluid
                    color="orange"
                    onClick={() => {
                      signOut();
                      auth.signOut();
                    }}
                  >
                    Logout
                  </Button>
                </Dropdown.Header>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        ) : (
          <Menu.Item
            name="Sign in to start tracking"
            onClick={openLoginModal}
          />
        )}
      </Menu.Menu>
    </Menu>
  );
}

export default Navbar;
