import React from "react";
import {
  Segment,
  Divider,
  Image,
  Form,
  Radio,
  Input,
  Dropdown,
  Button,
} from "semantic-ui-react";

function MobileNavbar({
  isDarkMode,
  darklogo,
  logo,
  setShowOwned,
  showOwned,
  setIsDarkMode,
  localSearch,
  setLocalSearch,
  setSearchTerm,
  selectedOptions,
  options,
  setSelectedOptions,
  currentUser,
  setCurrentUser,
  firebase,
  setOpen,
  setShowMobileMenu,
}) {
  return (
    <div>
      <div>
        <Segment inverted={isDarkMode}>
          <Image
            style={{ margin: "0 auto" }}
            size="small"
            src={isDarkMode ? darklogo : logo}
          />
          <Divider />
          <Form inverted={isDarkMode}>
            <Form.Group>
              <label>Show only my threads</label>
              <Form.Field
                toggle
                control={Radio}
                onChange={() => setShowOwned(!showOwned)}
                checked={showOwned}
              />
            </Form.Group>
            <Form.Group>
              <label>Dark Mode</label>
              <Form.Field
                toggle
                control={Radio}
                onChange={() => setIsDarkMode(!isDarkMode)}
                checked={isDarkMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                label="Search:"
                control={Input}
                value={localSearch}
                onChange={(e) => setLocalSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchTerm(localSearch);
                  }
                }}
                icon="search"
                placeholder="Search..."
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                label="Filter by type:"
                control={Dropdown}
                clearable
                placeholder="Thread Type"
                multiple
                search
                selection
                onChange={(e, { value }) => setSelectedOptions(value)}
                value={selectedOptions}
                options={options}
              />
            </Form.Group>
            <Form.Group>
              {currentUser ? (
                <Form.Field
                  label="Signed in as:"
                  control={Dropdown}
                  text={currentUser.email}
                  floating
                  labeled
                >
                  <Dropdown.Menu>
                    <Dropdown.Header>
                      <Button
                        fluid
                        color="orange"
                        onClick={() => {
                          setCurrentUser(null);
                          // firebase.auth().signOut();
                        }}
                      >
                        Logout
                      </Button>
                    </Dropdown.Header>
                  </Dropdown.Menu>
                </Form.Field>
              ) : (
                <Button onClick={() => setOpen(true)}>
                  Sign in to start tracking
                </Button>
              )}
            </Form.Group>
          </Form>
          <Divider />
          <Button
            onClick={() => setShowMobileMenu(false)}
            inverted={isDarkMode}
            fluid
            size="huge"
            color="olive"
          >
            Close menu
          </Button>
        </Segment>
      </div>
    </div>
  );
}

export default MobileNavbar;
