import React from "react";
import { Header, Divider, Button, Form } from "semantic-ui-react";
import "../index.css";

function InventoryForm({
  setRefetchInv,
  userInv,
  currentUser,
  firebase,
  thread,
  isDarkMode,
}) {
  const [inventory, setInventory] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [loading]);

  React.useEffect(() => {
    if (userInv?.sizes) {
      setInventory(userInv.sizes);
    } else {
      const blankInv = {};
      thread.sizes && thread.sizes.forEach((s) => (blankInv[s] = ""));
      setInventory(blankInv);
    }
  }, [thread.sizes, userInv]);

  function saveInventory() {
    setRefetchInv(true);
    for (let key in inventory) {
      if (inventory[key] === "") delete inventory[key];
    }
    if (currentUser)
      firebase
        .database()
        .ref(`/users/${currentUser.uid}/inventory/${thread.id}`)
        .update({
          sizes: inventory,
        });
  }

  return (
    <Form inverted={isDarkMode} size="small">
      <Divider />
      {thread.sizes && (
        <>
          <Header style={{ color: isDarkMode ? "white" : "black" }}>
            {thread.sizes.length <= 1
              ? thread.sizes[0] === 0
                ? "Inventory"
                : "Size"
              : "Sizes"}
          </Header>
          {thread.sizes?.map((size) => {
            size = size === 0 ? "How many do you own?" : size;
            return (
              <Form.Input
                key={size}
                value={inventory[size]}
                onChange={(e) => {
                  setInventory({ ...inventory, [size]: e.target.value });
                }}
                placeholder="Add inventory"
                label={size}
              >
                <input type="number" pattern="[0-9]*" />
              </Form.Input>
            );
          })}
        </>
      )}
      <div>
        <Button
          onClick={() => {
            saveInventory();
            setLoading(true);
          }}
          style={{ marginTop: "5px" }}
          color="blue"
        >
          {loading ? "Saved!" : "Save Inventory"}
        </Button>
        <Button
          style={{ marginTop: "5px" }}
          as="a"
          color="green"
          href={thread.url}
        >
          Order more
        </Button>
      </div>
    </Form>
  );
}

export default InventoryForm;
