import axios from "axios";
import classNames from "classnames";
import React from "react";
import MainTable from "./components/MainTable";
import { PAGE_SIZE } from "./constants";
import Navbar from "./components/Navbar";
import MobileNavbar from "./components/MobileNavbar";
import Login from "./components/Login";
import logo from "./logo.jpg";
import darklogo from "./darklogo.jpg";
import { Button, Modal, Header } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

const config = {
  apiKey: "AIzaSyAPjj8_4knYorRiK8TohHNlvJn7pAILoJ4",
  authDomain: "threadtracker-valdani.firebaseapp.com",
  databaseURL: "https://threadtracker-valdani.firebaseio.com",
  projectId: "threadtracker-valdani",
  storageBucket: "threadtracker-valdani.appspot.com",
  messagingSenderId: "959759091956",
  appId: "1:959759091956:web:55b9f7068ac373de5bf6a1",
  measurementId: "G-GYG40YFSCW",
};

firebase.initializeApp(config);
firebase.analytics();

function App() {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [inv, setInv] = React.useState(null);
  const [subset, setSubset] = React.useState([]);
  const [showOwned, setShowOwned] = React.useState(false);
  const [refetchInv, setRefetchInv] = React.useState(true);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [localSearch, setLocalSearch] = React.useState("");

  React.useEffect(() => {
    axios
      .get("https://threadtracker-valdani.firebaseio.com/threads.json")
      .then((r) => {
        setData(r.data);
        setOptions(
          Array.from(new Set(r.data.map((d) => d.type))).map((d) => ({
            value: d,
            text: d,
            key: d,
          }))
        );
      });
  }, []);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) setCurrentUser(user);
  });

  React.useEffect(() => {
    if (searchTerm) return;
    if (currentUser?.uid) {
      if (refetchInv) setRefetchInv(false);
      firebase
        .database()
        .ref(`/users/${currentUser?.uid}/inventory`)
        .once("value")
        .then((snapshot) => {
          setInv(snapshot.val());
        });
    }
  }, [currentUser, refetchInv, setRefetchInv, searchTerm]);

  React.useEffect(() => {
    if (open) {
      firebase.analytics().logEvent("open_login_modal");
    }
  }, [open]);

  React.useEffect(() => {
    if (showOwned) {
      setSubset(data.filter((d) => !!inv?.[d.id]));
    } else {
      setSubset(data.filter((d, i) => i < page * PAGE_SIZE));
    }
  }, [page, setSubset, data, showOwned, inv]);

  React.useEffect(() => {
    if (!searchTerm && selectedOptions.length < 1) {
      setSubset(data.filter((_, i) => i < page * PAGE_SIZE));
    } else {
      const matchingTitles = data.filter((d) =>
        d.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchingTags = data.filter((d) =>
        d.tags.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const titlesAndTagsMatched = Array.from(
        new Set([].concat(...matchingTitles, ...matchingTags))
      );
      if (selectedOptions.length > 0) {
        setSubset(
          titlesAndTagsMatched
            .filter((d) => selectedOptions.includes(d.type))
            .filter((_, i) => i < page * PAGE_SIZE)
        );
      } else {
        setSubset(titlesAndTagsMatched.filter((_, i) => i < page * PAGE_SIZE));
      }
    }
  }, [selectedOptions, searchTerm, setSubset, data, page]);
  return (
    <>
      <div
        className={classNames(
          isDarkMode ? "darkMode" : "normalMode",
          isMobile ? "noPadding" : "padding"
        )}
      >
        <Modal
          size="mini"
          closeIcon
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Modal.Header>Sign in to track.</Modal.Header>
          <Modal.Content>
            <Login
              firebase={firebase}
              setOpen={setOpen}
              auth={firebase.auth()}
            />
          </Modal.Content>
        </Modal>
        {!isMobile && (
          <Navbar
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            options={options}
            setSearchTerm={setSearchTerm}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            currentUser={currentUser}
            auth={firebase.auth()}
            openLoginModal={() => setOpen(true)}
            signOut={() => setCurrentUser(null)}
            setShowOwned={setShowOwned}
          />
        )}
        {showMobileMenu && (
          <MobileNavbar
            isDarkMode={isDarkMode}
            darklogo={darklogo}
            logo={logo}
            setShowOwned={setShowOwned}
            showOwned={showOwned}
            setIsDarkMode={setIsDarkMode}
            localSearch={localSearch}
            setLocalSearch={setLocalSearch}
            setSearchTerm={setSearchTerm}
            selectedOptions={selectedOptions}
            options={options}
            setSelectedOptions={setSelectedOptions}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            firebase={firebase}
            setOpen={setOpen}
            setShowMobileMenu={setShowMobileMenu}
          />
        )}
        <Header inverted={!!isDarkMode} style={{ padding: "20px" }}>
          {showOwned ? "My Threads" : "All Threads"}
        </Header>
        <MainTable
          selectedOptions={selectedOptions}
          searchTerm={searchTerm}
          isDarkMode={isDarkMode}
          setRefetchInv={setRefetchInv}
          setOpen={setOpen}
          userInv={inv}
          currentUser={currentUser}
          firebase={firebase}
          data={subset}
          page={page}
          setPage={setPage}
        />
      </div>
      {isMobile && (
        <Button
          circular
          primary
          size="massive"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          style={{ position: "fixed", bottom: 10, right: 10, zIndex: 100 }}
          icon={showMobileMenu ? "close" : "bars"}
        />
      )}
    </>
  );
}

export default App;
