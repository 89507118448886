import React from "react";
import { Card, Image } from "semantic-ui-react";

const ThreadCard = ({ onClick, thread, isDarkMode }) => {
  const defaultImage =
    "https://images.unsplash.com/photo-1517490970599-197965fbcef4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1047&q=80";
  return (
    <Card
      onClick={onClick}
      style={{
        cursor: "pointer",
        background: isDarkMode ? "#4a4a4a" : "#fff",
        border: "none",
        boxShadow: "none",
      }}
    >
      <Image
        src={thread.imgSrc || defaultImage}
        alt={thread.altText || thread.title}
      />
      <Card.Content>
        <Card.Header
          textAlign="center"
          style={{ color: isDarkMode ? "white" : "#1b1c1d" }}
        >
          {thread.Number}
          <br />
          {thread.type}
        </Card.Header>
      </Card.Content>
    </Card>
  );
};

export default ThreadCard;
